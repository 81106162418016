import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from '../components/layout'

class RootIndex extends React.Component {
  render() {
    let projects = get(this, 'props.data.allContentfulLabProject.edges');
    projects = projects.sort((a, b) => {
      return new Date(b.node.date) - new Date(a.node.date)
    });
    const options = {
      renderText: (text) => {
        return text
          .replace(/\u2028/g, "")
          .split("\n")
          .flatMap((text, i) => [i > 0 && <br key={i} />, text])
      }
    }

    return (
      <Layout location={this.props.location} title="Labs | Lotte Bijlsma">
        <main className="g-labs l-wrapper">
          <h1 className="u-visually-hidden">Labs</h1>
          <ul className="g-labs__projects">
            {projects.map((project, i) => {
              return (
                <li className="g-labs__project" key={i}>
                  <img
                      src={`${project.node.thumbnail.file.url}?fit=pad&w=1200`}
                      srcSet={`${project.node.thumbnail.file.url}?fit=pad&w=1200 1200w, ${project.node.thumbnail.file.url}?fit=pad&w=600 600w`}
                      />
                  <span class="u-visually-hidden">{project.node.name}</span>
                  <a className="g-labs__project-cta o-title-tiny" target="_blank" rel="noopener" href={project.node.url}>
                    <span>
                      To {project.node.externalPlatform}
                      <svg width="51" height="51" viewBox="0 0 51 51" fill="none">
                        <circle cx="25.5" cy="25.5" r="25" stroke="#F2EEE9"/>
                        <path d="M13.9936 37.7657L36.7657 14.9936M36.7657 14.9936L12.7785 14.9936M36.7657 14.9936L36.7657 38.9808" stroke="#F2EEE9" stroke-width="1.34247"/>
                      </svg>
                    </span>
                  </a>
                </li>
              )
            })}
          </ul>            
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query LabsQuery {
    allContentfulLabProject {
      edges {
        node {
          url
          date
          name
          externalPlatform
          thumbnail {
            file {
              url
            }
          }
        }
      }
    }
  }
`
